import { useEffect, useState } from "react";
import "./styles.css";
import Sidebar from "../../components/Sidebar";
import {
  Box,
  Button,
  Stack,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-hot-toast";
import * as MI from '@mui/icons-material';
import * as I from "../../components/Icons";
import Tab from "@mui/material/Tab";
import React from "react";
import AuthenticatedAvatar from "../../components/Common/authAvatar";
import config from '../../config.json';
import { useGlobalContext } from "../../utils/globalContext";
import { useTranslation } from "react-i18next";
import { GridColDef, GridCellParams, DataGrid, GridActionsCellItem, GridCallbackDetails, GridRowParams, MuiEvent } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import axiosInstance from "../../utils/axsioInstance";
import Moment from "react-moment";
import EditUser from "../../components/Settings/editUser";
import AddUser from "../../components/Settings/addUser";
import LoadingDialog from "../../components/Common/loadingDialog";
import { frFR } from '@mui/x-data-grid/locales';


interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Settings() {


  const {
    register :registerInfos,
    handleSubmit:handleSubmitInfos,
    trigger:triggerInfos,
    formState: { errors : errorsInfos },
    getValues:getValuesInfos,
    reset:resetInfos,
  } = useForm();

  const {
    register :registerPros,
    handleSubmit:handleSubmitPros,
    trigger:triggerPros,
    formState: { errors : errorsPros },
    getValues:getValuesPros,
    reset:resetPros,
  } = useForm();

  const {
    register :registerPass,
    handleSubmit:handleSubmitPass,
    trigger:triggerPass,
    formState: { errors : errorsPass },
    getValues:getValuesPass,
    watch:watchPass,
    reset:resetPass,
  } = useForm();
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 5 });
  const [refresh, setRefresh] = useState(true);
  const [errorPassword, seterrorPassword] = useState("");
  const [working, setWorking] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [curUser, setCurUser] = useState(null);
  
  const [search, setSearch] = useState("");

  const validatePassword = (value : string) => {
    const { new_password } = watchPass();
    return value === new_password || t('settings.password_mismatch');
  };

  const editInfos = async (data: any) => {
      try{
        const isValid = await triggerInfos([
          "fname",
          "lname",
          "email",
        ]);
      if (!isValid) {
       return;
      }
     setWorking(true);
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    formData.append('uuid', user?.uuid);
      const response = await axiosInstance
        .post(config.URLS.user_edit_profile, formData);

      if(response.data.success)
      {
          toast.success(t(response.data.message));
          setRefresh(true);
      }else{
          toast.error(t(response.data.message));
      }
    }
    catch(error){
      
        toast.error(t("common.request_error"));
    }
    finally{
      setWorking(false);
    }
  };

 
    const editPassword = async (data: any) => {
        try{
        setWorking(true);
     const isValid = await triggerPros([
          "new_password",
          "old_password"
        ]);

        if (!isValid) {
        return;
        }
        const response = await axiosInstance
          .post(config.URLS.user_password, data)

        if(response.data.success)
        {
           toast.success(t(response.data.message));
        }else{
           toast.error(t(response.data.message));
        }
      }
      catch(error){
        
         toast.error(t("common.request_error"));
      }
      finally{
        setWorking(false);
      }
    };

    const deleteUser = async (uuid: string) => {
        try{
        setWorking(true);
  
        const response = await axiosInstance
          .post(config.URLS.user_delete, {'uuid':uuid})

        if(response.data.success)
        {
           toast.success(t(response.data.message));
           setRefresh(true);
        }else{
           toast.error(t(response.data.message));
        }
      }
      catch(error){
        
         toast.error(t("common.request_error"));
      }
      finally{
        setWorking(false);
      } 
    };
    const toggleAdmin = async(uuid:string)=>{
        try{
        setWorking(true);
  
        const response = await axiosInstance
          .post(config.URLS.user_admin, {'uuid':uuid})

        if(response.data.success)
        {
           toast.success(t(response.data.message));
           setRefresh(true);
        }else{
           toast.error(t(response.data.message));
        }
      }
      catch(error){
        
         toast.error(t("common.request_error"));
      }
      finally{
        setWorking(false);
      } 
    };
    const blockUser = async(uuid:string)=>{
      try{
        setWorking(true);
  
        const response = await axiosInstance
          .post(config.URLS.user_block, {'uuid':uuid})

        if(response.data.success)
        {
           toast.success(t(response.data.message));
           setRefresh(true);
        }else{
           toast.error(t(response.data.message));
        }
      }
      catch(error){
        
         toast.error(t("common.request_error"));
      }
      finally{
        setWorking(false);
      } 
    };
    const showEdit = async(user:any)=>{
        setCurUser(user);
        setOpenEdit(true);
    };
        
  const columns: GridColDef[] = [
  {
    field: "username",
    headerName: "Utilisateur",
    flex: 1,
    cellClassName: "centered-content",
    renderCell: (params: GridCellParams) => (
      <div className="row center gap-16" style={{ position:"relative"}}>

      {(params.row.role=='admin' && params.row.suspended_at==null ) &&  (<MI.AdminPanelSettings style={{
                position:"absolute",
                color: "#00AA8D",
                top:0,
                left: -10,
                zIndex : 100
              }} />)}
      {params.row.suspended_at!=null  &&  (<MI.Block  color={'error'} style={{
                position:"absolute",
                top:0,
                left: -10,
                zIndex : 100
              }} />)}

        <AuthenticatedAvatar url={config.URLS.user_image+ params.row.avatar} />
        <div className="rating column gap-8 flex">
          <div className="item-name">{params.row.full_name}</div>
          <div className="stars row gap-4">
            {params.row.username}
          </div>
        </div>
      </div>
    ),
  },
  {
    field: "email",
    headerName: "E-mail",
    flex: 1,
    cellClassName: "centered-content",
  },
  {
    field: "tel",
    headerName: "Tèl",
    flex: 1,
    cellClassName: "centered-content",
  },
  {
    field: "created_at",
    headerName: "Crèe le",
    renderCell: (params: GridCellParams) => (
      <Moment format="DD/MM/YYYY">{params.row.created_at}</Moment>
    ),
    flex: 1,
    cellClassName: "centered-content",
  },
  {
    field: "actions",
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<MI.Edit />}
            label={t('common.edit')}
            onClick={()=>{showEdit(params.row)}}
            showInMenu
          />,
          <GridActionsCellItem
            icon={params.row.role=='admin'?<MI.RemoveModerator color="info" />:<MI.AddModerator  color="warning" />}
            label={params.row.role=='admin'?t('settings.define_user'):t('settings.define_admin')}
            onClick={()=>{toggleAdmin(params.row.uuid)}}
            showInMenu
          />,
          <GridActionsCellItem
            icon={params.row.suspended_at==null?<MI.Block color="error" />:<MI.CheckCircle  color="success"/>}
            label={params.row.suspended_at==null?t('common.block'):t('common.unblock')}
            onClick={()=>{blockUser(params.row.uuid)}}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<MI.Delete />}
            label={t('common.delete')}
            onClick={()=>{deleteUser(params.row.uuid)}}
            showInMenu
          />,
        ],
    headerName: "", 
    cellClassName: "centered-content",

  },
];
  const [value, setValue] = React.useState(0);
  const [t] = useTranslation("common");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { user, setUser } = useGlobalContext();

  useEffect(() => {
    const fetchData = async () => {
       if(!refresh)
          return;
      try {
    
        const response = await axiosInstance.get(`${config.URLS.user_list}?search=${search}`);
        setRows(response.data);
        setRefresh(false);

      } catch (error) {
        console.error("Error fetching data:", error);

      }
    };

    fetchData();
    
  }, [refresh]);
  
  return (
    <div className="settings page">
      <div className="row">
        <Sidebar />
        <div className="content flex">
          <div className="row main-header">
            <div className="page-title row gap-16 center">
              <I.Settings />
              <h1>Paramètres</h1>
            </div>
          </div>
          <div className="divider-56"></div>

          <div className="bloc">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Profil" />
              <Tab label="Sécurité" />
                  {user.role=='admin' &&
              <Tab label="Utilisateurs" />}
            </Tabs>
            <TabPanel value={value} index={0}>
              <Typography>
                <div className="wrapper">
                  <h2>Avatar</h2>
                    <Stack spacing={10} direction="row">
                    <AuthenticatedAvatar
                      alt={user.fname+" "+user.lname}
                      url={'/users/avatars/'+user.avatar}
                      sx={{ width: 128, height: 128 }}
                    />
                   <form onSubmit={handleSubmitInfos(editInfos)}>
                  <Stack spacing={5} flex={1} direction="row">
                     <Stack spacing={2} direction="column">
                    <TextField  helperText={
                      errorsInfos.lname ? t("login.required_field") : ""
                    } {...registerInfos("lname", { required: true })} label={t('settings.last_name')}  style={{flex:0.3}} variant="outlined"  defaultValue={user.lname}/>
                    <TextField  helperText={
                      errorsInfos.fname ? t("login.required_field") : ""
                    } {...registerInfos("fname", { required: true })} label={t('settings.first_name')}  style={{flex:0.3}} variant="outlined"  defaultValue={user.fname}/>
                  </Stack>
                         <Stack spacing={2} direction="column">
                    <TextField  {...registerInfos("tel", { required: true })} label={t('settings.tel')}  style={{flex:0.3}} variant="outlined"  defaultValue={user.tel}/>
                    <TextField  helperText={
                      errorsInfos.email ? t("login.required_field") : ""
                    } {...registerInfos("email", { required: true })} label={t('settings.email')}    style={{flex:1}}  defaultValue={user.email} variant="outlined" />
                  </Stack>
                  </Stack>
                 </form>
                 </Stack>
                 
                 {(<Button onClick={handleSubmitInfos(editInfos)} style={{marginTop:20}} variant="contained">Enregistrer</Button>)}

                </div>


              </Typography>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Typography>
                <div className="wrapper">
                  <h2>Modifier mon mot de passe</h2>
                  <form onSubmit={handleSubmitPros(editPassword)}>
                  <Stack spacing={1} flex={1} direction="row">

                    <TextField   sx={{ width: "100%" }} 
                     {...registerPass("old_password", { required: true })}
                      type="password" 

                      helperText={
                      errorsPass.old_password ? t("login.required_field") : ""
                    }
                      label={t('settings.old_password')}
                      variant="outlined"
                    />
                    <TextField  sx={{ width: "100%" }} 
                     {...registerPass("new_password", { required: true })}
                      type="password"
                       helperText={
                      errorsPass.new_password ? t("login.required_field") : ""
                    }
                      label={t('settings.new_password')}
                      variant="outlined"
                    />
                    <TextField  sx={{ width: "100%" }} 
                     {...registerPass("confirm_password", { required: true , validate: validatePassword })}
                      type="password"
                      
                       helperText={
                          errorsPass.confirm_password ? t("settings.password_mismatch") : ""
                        }
                      label={t('settings.confirm_password')}
                      variant="outlined"
                    />
                  </Stack>
                  </form>
                </div>
                <Button onClick={handleSubmitPass(editPassword)} variant="contained">Enregistrer</Button>
              </Typography>
            </TabPanel>
            {user.role=='admin' &&
            <TabPanel value={value} index={2}>
              <Typography>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          variant="contained"
                          startIcon={<MI.Add />}
                          onClick={() => { setOpenAdd(true) }}
                        >
                          {t("common.add")}
                        </Button>
                      </div>
                 <DataGrid 
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    style={{minHeight:200,marginTop:24}}
                    getRowId={(row) => row.uuid}
                    getRowHeight={() => 'auto'}
                    autosizeOnMount
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {pageSize:10},
                      },
                    }}
                    onRowClick={(
                        params: GridRowParams,
                        event: MuiEvent,
                        details: GridCallbackDetails,
                      ) => {
                        setCurUser(params.row);
                      }} 
                  />
              </Typography>
            </TabPanel>}
          </div>
        </div>
      </div>
      <EditUser user={curUser} setRefresh={setRefresh} open={openEdit} setOpen={setOpenEdit} />
     { 
     <AddUser  setRefresh={setRefresh} open={openAdd} setOpen={setOpenAdd} />
     }
    <LoadingDialog open={working} />
    </div>
  );
}
