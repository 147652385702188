import { useState, useEffect } from "react";
import "./addClient.css";
import LoadingDialog from "../../Common/loadingDialog";

import { styled } from "@mui/material/styles";
import { color } from "@mui/system";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { toast } from "react-hot-toast";
import StepLabel from "@mui/material/StepLabel";
import axiosInstance from "../../../utils/axsioInstance";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import config from "../../../config.json";
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN = 'pk.eyJ1IjoidW5lcyIsImEiOiJjbHM0cWtlOTMwOWZhMnBvMHFxeDV1N3piIn0._t0RpNVF2pmN48ofIT8Dig'; 

function AddClient({
  open,
  setRefresh,
  setOpen,
  
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;

}) {
  const { t } = useTranslation("common");

  const [working, setWorking] = useState(false);
    const [position, setPosition] = useState<[number, number] | null>(null);

  const handleNext = async () => {
    
      const isValid = await trigger();
     
      if (isValid) {
        handleSubmit(onSubmit)();
      }
    
    
  };


  const closeDialog = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm();


  const onSubmit = async (data: any) => {
     setWorking(true);

    try{
       
      const response = await axiosInstance.post(config.URLS.client_add, {
        ...data,
        latitude: position ? position[0] : null,
        longitude: position ? position[1] : null,
      });

        if(response.data.success)
        {
           toast.success(t(response.data.message));
           setRefresh(true);
        }else{
           toast.error(t(response.data.message));
        }
      
    }
      catch(error) {
         toast.error(t("common.request_error"));
      }finally{
        setWorking(false);
        setOpen(false);
      }
  };
  useEffect(() => {
    if (open) {
      reset();
    }
    getCities();
  }, [open, reset]);
      const [cities,setCities] =useState([]);
     const getCities = async ()=>{
        const response = await axiosInstance.get(config.URLS.client_cities);
        setCities(response.data);
     };
  const handleMapClick = (event : any) => {
    const { lngLat } = event;
    setPosition([lngLat.lat, lngLat.lng]);
    setValue("latitude", lngLat.lat);
    setValue("longitude", lngLat.lng);
  };
  return (
    <>
        <Dialog
      className="user-dialog"
      open={open}
      onClose={closeDialog}
      fullWidth={true}
      PaperProps={{
        sx: {
          width: 1100,
        },
      }}
    >
      <DialogContent className="step-wizard">
        <form onSubmit={handleSubmit(onSubmit)}>
        
            <div className="step">
              <div className="step-header">
                <h2>{t("clients.user_infos")}</h2>
              </div>

              <Stack direction="row" style={{marginBottom:32,width:"100%"}} spacing={2}>
         

                <div className="form flex-d" style={{width:"100%"}} >
                  <TextField
                    style={{flex:3}}
                    className="texts-field"
                    {...register("magasin", { required: true })}
 
                    error={!!errors.magasin}
                    helperText={errors.magasin ? t("login.required_field") : ""}
                    label={t("clients.magasin") }
                    variant="outlined"
                  />

                  <TextField
                    style={{flex:3}}

                    className="texts-field"
                    {...register("reference", { required: true })}
                    
                    
                    error={!!errors.fname}
                    helperText={
                      errors.fname ? t("login.required_field") : ""
                    }
                    label={t("clients.reference") }
                    variant="outlined"
                  />
                  
                  <TextField
                    style={{flex:3}}

                    className="texts-field"
                    {...register("tel", {
                       pattern: {
                            value:/(\+)?(\(?\d+\)?)(([\s-]+)?(\d+)){0,}/,
                            message: t('common.phone_invalid'),
                          },
                    })}
                      
                    error={!!errors.tel}
                    helperText={
                      errors.tel ? t("login.required_field") : ""
                    }
                    label={t('settings.tel')}
                    variant="outlined"
                  />
                </div>
              </Stack>
              <Stack direction="row"  style={{marginBottom:32,width:"100%"}} spacing={2}>
         

                <div className="form" style={{width:"100%"}}>
                 

                
                  <TextField
                     style={{flex:4}}
                    className="textx-field"
                    {...register("lname", { required: true })}
                    
                    error={!!errors.lname}
                    helperText={errors.lname ? t("login.required_field") : ""}
                    label={t("settings.last_name") }
                    variant="outlined"
                  />

                  <TextField
                                      style={{flex:4}}

                    className="texxt-field"
                    {...register("fname", { required: true })}
                    
                    
                    error={!!errors.fname}
                    helperText={
                      errors.fname ? t("login.required_field") : ""
                    }
                    label={t("settings.first_name") }
                    variant="outlined"
                  />

                  <TextField
                    style={{flex:4}}
                    className="texxt-field"
                    {...register("email", {
                      required: true,
                            pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: t('common.email_invalid'),
                          },
                    })}
                    
                    error={!!errors.email}
                    helperText={
                      errors.email ? t("login.required_field") : ""
                    }
                    label={t('settings.email')}
                    variant="outlined"
                  />

                  <TextField
                    style={{flex:4}}

                    className="texxt-field"
                    {...register("password", { required: true })}
                    
                    error={!!errors.password}
                    helperText={
                      errors.password ? t("login.required_field") : ""
                    }
                    label={t("login.password") }
                    variant="outlined"
                  />

                </div>
              </Stack>
              <Stack direction="row" style={{marginBottom:32,width:"100%"}}  spacing={2}>
                <div className="form" style={{width:"100%"}}>
                 

                
                  <TextField
                    style={{flex:4}}

                    className="textx-field"
                    {...register("address")}
                    
                    error={!!errors.address}
                    helperText={errors.address ? t("login.required_field") : ""}
                    label={t("clients.address") }
                    variant="outlined"
                  />

                 <FormControl style={{ flex: 1 }} variant="outlined"  error={!!errors.city}>
                    <InputLabel>{t("clients.city")}</InputLabel>
                    <Select
                      {...register("city", { required: true })}
                      label={t("clients.city")}
                    >
                      {cities.map((city: any) => (
                        <MenuItem key={city.city_id} value={city.city_id}>
                          {city.city_name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.city && <p className="MuiFormHelperText-root Mui-error">{t("login.required_field")}</p>}
                  </FormControl>
                </div>
              </Stack>

              <Stack direction="row" style={{ marginBottom: 32, width: "100%" }} spacing={2}>
                <div className="form" style={{ width: "100%" }}>
                  <Map
                    initialViewState={{
                      latitude: 33.589277,
                      longitude: -7.5876162,
                      zoom: 16
                    }}
                    style={{ width: '100%', height: 400 }}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    mapboxAccessToken={MAPBOX_TOKEN}
                    onClick={handleMapClick}
                  >
                    {position && (
                      <Marker
                        latitude={position[0]}
                        longitude={position[1]}
                                         />
                    )}
                  </Map>
                </div>
              </Stack>
            </div>
        
        </form>
      </DialogContent>

      <Stack direction="row" className="dialog-actions">
     
        <div className="row btn-actions gap-24">

          <Button  variant="outlined" onClick={()=>{setOpen(false)}} >
              {t("common.cancel")}
            </Button>
          
          
          <Button variant="contained" onClick={handleNext}>
          
              {t("common.add")}
          </Button>
        </div>
      </Stack>
    </Dialog>
    <LoadingDialog open={working} />
    </>
  );
}

export default AddClient;
