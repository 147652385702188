
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Protected from './utils/protected';
import Dashboard from './pages/dashboard';
import Clients from './pages/clients';
import UnProtected from './utils/unprotected';
import LoginPage from './pages/login';
import Products from './pages/products';
import { GlobalProvider } from './utils/globalContext';
import Orders from './pages/orders';
import Banners from './pages/banners';
import Settings  from './pages/settings';
import ResetPassword from './pages/resetPassword';
import ChangePassword from './pages/changepassword';
import { useState, useEffect } from 'react';
import SmallScreenMessage from './pages/smallscreen';

function App() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);


    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return isSmallScreen ?   <SmallScreenMessage /> : 
  <GlobalProvider>
    <BrowserRouter>
    <Routes >
      <Route path="/"  element={ <Protected><Dashboard /></Protected>} />
      <Route path="/login" element={  <UnProtected><LoginPage /></UnProtected>} />
      <Route path="/dashboard" element={  <Protected><Dashboard /></Protected>}/>
      <Route path="/clients" element={  <Protected><Clients /></Protected>}/>
      <Route path="/products" element={  <Protected><Products /></Protected>}/>
      <Route path="/orders" element={  <Protected><Orders /></Protected>}/>
      <Route path="/banners" element={  <Protected><Banners /></Protected>}/>
      <Route path="/settings" element={  <Protected><Settings /></Protected>}/>
      <Route path="/resetPassword" element={  <UnProtected><ResetPassword /></UnProtected>}/>
      <Route path="/do-reset-password/:userID" element={  <UnProtected><ChangePassword /></UnProtected>}/>
      
    </Routes >
</BrowserRouter>
</GlobalProvider>;
  
}

export default App;
