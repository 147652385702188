

import {Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as MI from '@mui/icons-material';
import * as I from '../Icons';
import { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axsioInstance';
import { useTranslation } from 'react-i18next';
import config from '../../config.json'
import AuthenticatedImage from '../Common/authImg';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import toast from 'react-hot-toast';
import EditProductModal from './editProduct';
import LoadingDialog from '../Common/loadingDialog';

   
 function ProductPreview({ productData ,setRefresh,setProduct}: { productData: any,setRefresh: React.Dispatch<React.SetStateAction<boolean>>,setProduct: React.Dispatch<React.SetStateAction<any>> } ) {
  
    const [loading,setLoading] = useState(false);
    const [open_confirm,setOpenConfirm] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
  const [working, setWorking] = useState(false);
    
    const {t} = useTranslation('common');


    return (
        <>
        
      <EditProductModal  product={productData}  open={editOpen} setRefresh={setRefresh} setOpen={setEditOpen} />
      <Dialog open={open_confirm} onClose={()=>{setOpenConfirm(false)}}>
        <DialogTitle>{t('common.confirmation')}</DialogTitle>
        <DialogContent>
          <p>{t('common.confirm_delete')}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpenConfirm(false)}} color="primary">
            {t('common.no')}
          </Button>
          <Button onClick={async ()=>{
         try{
                setWorking(true);
                const response  = await  axiosInstance
                .post(config.URLS.delete_product, {
                    "product" : productData?.id
                }, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                });
               
                    if(response.data.success)
                    {
                        toast.success(t(response.data.message));
                        setRefresh(true);
                        setOpenConfirm(false);
                        setProduct(null);
                    }else{
                    toast.error(t(response.data.message));
                    }
                
                
            }catch(error) {
                    
                    toast.error(t("common.request_error"));
            }
            finally{
                setWorking(false);
            }
          }} color="primary" autoFocus>
               {t('common.yes')}
          </Button>
        </DialogActions>
      </Dialog>
        <div className="bloc phone-preview">
            <div className="close-details-container" onClick={()=>{setProduct(null)}}><I.Close  /></div>

            {loading ? (
                    <>
                    <div className="carousel-preview">
                    <Skeleton height={300} width={'100%'} />
                    </div>
                    <div className="wrapper">
                        <Skeleton count={1} />
                    
                        <div className="desc"><Skeleton /></div>
                        <Skeleton />
                        <Skeleton  count={8} />
                    </div>
                    
                    </>
                ): (
                    <>
                        <div className="carousel-preview">
                            <AuthenticatedImage width="100%" url={config.URLS.product_image + productData.pic} />
                        </div>
                        <div className="wrapper">
                        <h1>{productData.product_name}</h1>

                        <div className="desc" style={{paddingBottom:32}}>{productData.description}</div>
                        
                        <div className="row btn-actions gap-8" >
                            <Button variant="outlined" 
                            color="warning" 
                            onClick={()=>{setEditOpen(true)}} 
                            startIcon={<MI.Edit />}>{t('products.edit_product')}</Button>
                            <Button
                                variant="outlined"
                                color="error"
                                startIcon={<MI.Delete />}
                                onClick={()=>{setOpenConfirm(true)}}
                            >
                                {t('products.delete_product')}
                            </Button>
                        </div>
                       </div>
                    </>
                )}
            </div>
                  <LoadingDialog open={working} />
            </>
        
    );
}

export default ProductPreview;