import { ChangeEvent, useEffect, useState } from "react";
import "./styles.css";
import Sidebar from "../../components/Sidebar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputAdornment,
  TextField,
} from "@mui/material";
import * as MI from '@mui/icons-material';
import * as I from "../../components/Icons";
import AuthenticatedAvatar from "../../components/Common/authAvatar";
import config from '../../config.json';
import { useTranslation } from "react-i18next";
import { GridColDef, GridCellParams, DataGrid, GridActionsCellItem, GridCallbackDetails, GridRowParams, MuiEvent } from "@mui/x-data-grid";
import axiosInstance from "../../utils/axsioInstance";
import Moment from "react-moment";
import LoadingDialog from "../../components/Common/loadingDialog";
import Lottie from "lottie-react";
import NoData from "../../animations/no-data.json";
import ClientDetails from "../../components/Clients/clientDetails";
import AddClient from "../../components/Clients/addClient";
import debounce from "lodash/debounce";
import { frFR } from '@mui/x-data-grid/locales';
import toast from "react-hot-toast";


export default function Clients() {

  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedClient, setselectedClient] = useState<any>(null);
  const [search, setSearch] = useState("");
  const [openNotify, setOpenNotify] = useState(false);
  const [notif_title, setNTitle] = useState('');
  const [notif_body, setNBody] = useState('');
  const handleChangeT = (event :  ChangeEvent<HTMLInputElement>) => {
    setNTitle(event.target.value);
  };    const handleChangeB = (event :  ChangeEvent<HTMLInputElement>) => {
    setNBody(event.target.value);
  };  
   const handleSend = async() => {
    try{
      if(notif_title==''){
       toast.error(t('clients.type_title'));
        return;
      }
      if(notif_body==''){
       toast.error(t('clients.type_title'));
        return;
      }
      setWorking(true);
    const response = await axiosInstance.post(config.URLS.client_send_notif_all,{
        'title' : notif_title,
        'body' : notif_body,
      });
      if(response.data.success){
        toast.success(t(response.data.message));
        setNBody('');
        setNTitle('');
      }else{
          toast.error(t(response.data.message));
      }
   }
     catch(error){
          toast.error(t('common.request_error'));
      }
      finally{
        setWorking(false);
         setOpenNotify(false);
      }
  };
  const debouncedSearch = debounce((searchValue) => {
    setSearch(searchValue); 
    setRefresh(true);
  }, 1000);

  const columns: GridColDef[] = [
  {
    field: "magasin",
    headerName: "Client",
    flex: 1,
    cellClassName: "centered-content",
    renderCell: (params: GridCellParams) => (
      <div className="row center gap-16" style={{ position:"relative"}}>

      {(params.row.verified_at!=null && params.row.suspended_at==null ) &&  (<MI.AdminPanelSettings style={{
                position:"absolute",
                color: "#00AA8D",
                top:0,
                left: -10,
                zIndex : 100
              }} />)}
      {params.row.suspended_at!=null  &&  (<MI.Block  color={'error'} style={{
                position:"absolute",
                top:0,
                left: -10,
                zIndex : 100
              }} />)}
   {(params.row.suspended_at==null && params.row.validated_at!=null) &&  (<MI.CheckCircle  color={'primary'} style={{
                position:"absolute",
                top:0,
                left: -10,
                zIndex : 100
              }} />)}
       {(params.row.suspended_at==null && params.row.validated_at==null) &&  (<MI.HourglassBottom  color={'warning'} style={{
                position:"absolute",
                top:0,
                left: -10,
                zIndex : 100
              }} />)}
        <AuthenticatedAvatar url={config.URLS.user_image+ params.row.avatar} />
        <div className="rating column gap-8 flex">
          <div className="item-name">{params.row.magasin}</div>
          <div className="item-name" style={{fontSize:10}} >{params.row.full_name}</div>
        </div>
      </div>
    ),
  },
  {
    field: "email",
    headerName: "Contact",
    flex: 1,
     renderCell: (params: GridCellParams) => (<>
        <div className="d-flex" >
        <small style={{width:'100%'}}>E-mail : {params.row?.email}</small> 
        <small style={{width:'100%'}}>Tèl : {params.row?.tel}</small>
        </div> 
     </>),
    cellClassName: "centered-content",
  },
  {
    field: "city_name",
    headerName: "Ville",
    flex: 1,
    cellClassName: "centered-content",
  },
  {
    field: "created_at",
    headerName: "Crèe le",
    renderCell: (params: GridCellParams) => (
      <Moment format="DD/MM/YYYY">{params.row.created_at}</Moment>
    ),
    flex: 1,
    cellClassName: "centered-content",
  },
];
  const [t] = useTranslation("common");

  const [working, setWorking] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
       if(!refresh)
          return;
      try {
        setWorking(true);
        const response = await axiosInstance.get(`${config.URLS.client_list}?search=${search}`);
        setRows(response.data);
        setDataLoaded(true);
        setRefresh(false);

      } catch (error) {
        console.error("Error fetching data:", error);

      }finally{
        setWorking(false);
      }
    };

    fetchData();
    
  }, [refresh]);

  
  return (
     <div className="orders page">
    <div className="row">
      <Sidebar  />
      <div className="content flex">
        <div className="row main-header">
          <div className="page-title row gap-16 center">
            <I.Orders/>
            <h1>{t('dashboard.clients')}</h1>
          </div>
              <FormControl size="small" sx={{ display: "flex", alignItems: "flex-end" }}>
              <Input
                className="search-input"
                disableUnderline
                startAdornment={
                    <InputAdornment position="start">
                      <I.Search />
                    </InputAdornment>
                  }
                placeholder={t("clients.search")}
                onChange={(event)=>{ debouncedSearch(event.target.value);}}
              />
            </FormControl>
            <div className=" row gap-16">
            <Button
            disableElevation
              variant="contained"
              startIcon={<MI.Add />}
              onClick={()=>{setOpenAdd(true)}}
            >
              {t("clients.add_client")}
            </Button>
            <Button
            disableElevation
              variant="contained"
              startIcon={<MI.Send />}
              onClick={()=>{setOpenNotify(true)}}
            >
              {t("clients.notify_all")}
            </Button>
            </div>
        </div>
        <div className="divider-56"></div>
        
        <div className="column gap-24">
          <div className="row gap-24">
            <div className="flow-container">
                   
              <div className="data-grid-container bloc flex">
                
                      {(rows.length<=0)?
                  ( <div className="bloc no-data">
                      <Lottie animationData={NoData}  style={{ height: 160 }} />
                      <h1>{ dataLoaded?t('clients.no_data'):t('common.loading')}</h1>
                    </div>):
               ( 
             
                <DataGrid
                  localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                  getRowHeight={() => 'auto'}
                  autosizeOnMount
                  getRowId={(row) => row?.uuid}
                  rows={rows}
                  pagination
                  columns={columns}
                          onRowClick={(
                        params: GridRowParams,
                        event: MuiEvent,
                        details: GridCallbackDetails,
                      ) => {
                        setselectedClient(params.row);
                      }}
                  initialState={{
                      pagination: {
                        paginationModel: {pageSize:10},
                      },
                    }}

                />
                
                )}
            </div>
            </div>
              {selectedClient && (
                <>
                    <ClientDetails clientData={selectedClient}  setClientData={setselectedClient} setRefresh={setRefresh}  />
            </>
            )}
              <AddClient  open={openAdd} setRefresh={setRefresh} setOpen={setOpenAdd} />
              <LoadingDialog open={working} />
              <Dialog open={openNotify} onClose={()=>{setOpenNotify(false)}}>
                  <DialogTitle>{t('clients.do_notify_client')}</DialogTitle>
                  <DialogContent>
      
                    <TextField
                      autoFocus
                      margin="dense"
                      id="reference"
                      label={t('clients.title_notif')}
                      fullWidth
                      value={notif_title}
                      onChange={handleChangeT}
                    />
                      <TextField
                      autoFocus
                      margin="dense"
                      id="title_notif"
                      multiline={true}
                      
                      label={t('clients.body_notif')}
                      fullWidth
                      value={notif_body}
                      onChange={handleChangeB}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={()=>{setOpenNotify(false)}} color="primary">
                      {t('common.cancel')}
                    </Button>
                    <Button onClick={handleSend} color="secondary">
                      {t('common.send')}
                    </Button>
                  </DialogActions>
            </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  );
}
