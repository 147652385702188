import "./styles.css";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import config from "../../config.json";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../utils/axsioInstance";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../utils/globalContext";
export default function LoginPage() {
  const { t } = useTranslation("common");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const { user, setUser } = useGlobalContext();

  const onSubmit = async (values: any) => {
    try {
      const response = await axiosInstance.post(config.URLS.log_in, values);

      if (response.status !== 200) {
        toast.error(t("common.request_error"));
      }
      if (response.data.success) {
        setUser({
          name: "JAAAN DOE",
        });
        localStorage.setItem("user-token", response.data.token);
        toast.success(t("login." + response.data.message));
        navigate("/dashboard");
      } else {
        toast.error(t("login." + response.data.message));
      }
    } catch (error) {
      console.log(error);
      toast.error(t("common.request_error"));
    }
  };

  return (
    <div className={"container"}>
      <div className={"content"}>
        <div className={"logo"}>
          <img
            src="/images/logo.png"

            height={64}
            alt="Picture of the author"
            className={"logoImg"}
          />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <h1 className={"h1"}>{t("login.authnetication")}</h1>
            <TextField
              id="outlined-required"
              {...register("username", { required: true })}
              placeholder={t("login.username")}
              label={t("login.username")}
              margin="normal"
            />
            {errors.username && errors.username.type === "required" && (
              <span>{t("login.required_field")}</span>
            )}
            <TextField
              id="outlined-required"
              label={t("login.password")}
              {...register("password", { required: true })}
              margin="normal"
              placeholder={t("login.password")}
              type="password"
            />
            {errors.password && errors.password.type === "required" && (
              <span>{t("login.required_field")}</span>
            )}
            <Button
              className={"btn"}
              type="submit"
              size="large"
              variant="contained"
              disableElevation
            >
              {t("login.connect")}
            </Button>

            <Button onClick={()=>{navigate('/resetPassword')}} href="#text-buttons">{t("login.forgot_password")}</Button>
          </Stack>
        </form>
      </div>
    </div>
  );
}
