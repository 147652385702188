import { useState, useEffect } from "react";
import "./addProduct.css";
import LoadingDialog from "../../Common/loadingDialog";
import { styled } from "@mui/material/styles";
import { toast } from "react-hot-toast";
import axiosInstance from "../../../utils/axsioInstance";
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import * as MI from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import config from "../../../config.json";

function AddProductModal({
  open,
  setRefresh,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation("common");


  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedImages((prevSelectedImages) => [
        ...prevSelectedImages,
        ...Array.from(files),
      ]);
    }
  };


  const handleDeleteImage = (index: number) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [messageOpen, setMessageOpen] = useState(false);
  const [working, setWorking] = useState(false);

  const handleNext = async () => {


      const res = await trigger([
        "name",
        "description",
      ]);

      setMessageOpen(false);

      if (selectedImages.length <= 0) {
        setMessageOpen(true);
      }

      if (!res || selectedImages.length <= 0) {
        console.log('there is a prnlm');
        return;
      }
              console.log('there is NO prnlm');

      //POST DATA HERE
          handleSubmit(onSubmit)();
    }



  const closeDialog = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    getValues,
    reset,
  } = useForm();

  const onSubmit = async (data: any) => {
    try{
      setWorking(true);
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    selectedImages.forEach((image, index) => {
      formData.append(`pictures[${index}]`, selectedImages[index]);
    });

    const response = await axiosInstance
      .post(config.URLS.add_product, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

        if(response.data.success)
        {
           toast.success(t(response.data.message));
           setRefresh(true);
        }else{
           toast.error(t(response.data.message));
        }
      }
      catch(error){
        
         toast.error(t("common.request_error"));
      }
      finally{
        setWorking(false);
      }
  };
  useEffect(() => {
    if (open) {
      reset();
    
      setSelectedImages([]);
    }
  }, [open, reset]);

  return (
    <>
    <Dialog
      className="product-dialog"
      open={open}
      onClose={closeDialog}
      fullWidth={true}
      PaperProps={{
        sx: {
          width: 1100,
        },
      }}
    >
      <DialogContent className="step-wizard">
        <form onSubmit={handleSubmit(onSubmit)}>
        
            <div className="step">
              <div className="step-header">
                <h2>{t("products.infos")}</h2>
                <div className="subtitle">{t("products.complete")}</div>
              </div>

              <Stack direction="row" spacing={2}>
                <div className="uploader">
                  <div className="zone">
                    <Stack
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Stack className="dropzone" component="label">
                        <MI.Upload color="primary" />
                        <VisuallyHiddenInput
                     
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleImageSelect}
                        />
                      </Stack>
                      <div className="tips">
                        Cliquez sur l'icone pour charger vos photos
                      </div>
                      <div className="subtitle">
                        format acceptée : JPG, PNG, 2 Mo MAX
                      </div>
                    </Stack>
                  </div>
                  <div
                    style={{
                      color: "red",
                      marginTop: 10,
                      marginBottom: 10,
                      textAlign: "center",
                      width: "100%",
                      display: messageOpen ? "block" : "none",
                    }}
                  >
                    {t("products.pictures")}
                  </div>
                  <div className="row gap-8 img-preview">
                    {selectedImages.map((image, index) => (
                      <div className="img flex" key={index}>
                        <img
                          width="50px"
                          alt={`ph-${index}`}
                          src={URL.createObjectURL(image)}
                        />
                        <MI.Cancel
                          className="icon-delete"
                          onClick={() => handleDeleteImage(index)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <Stack direction="column" spacing={2}>
                <div className="form">
                  <TextField
                    size="small"
                    className="text-field"
                    {...register("name", { required: true })}
                    error={!!errors.name}
                    helperText={errors.name ? t("login.required_field") : ""}
                    label="Nom"
                    variant="outlined"
                  />

                  <TextField
                  size="small"
                    fullWidth
                    
                    {...register("description", { required: true })}
                    error={!!errors.description}
                    helperText={
                      errors.description ? t("login.required_field") : ""
                    }
                    id="outlined-textarea"
                    label="Description"
                    placeholder="Max 250 Mots..."
                    multiline
                    rows={9}
                  />
         
                </div></Stack>
              </Stack>
            </div>
          

  
        </form>
      </DialogContent>

      <Stack direction="row" className="dialog-actions">
        <div className="row flex gap-16">

        </div>

        <div className="row btn-actions gap-24">
          
          <Button variant="outlined" onClick={()=>{setOpen(false);}}>
              {t("common.cancel")}
          </Button>
          <Button variant="contained" onClick={handleNext}>
              {t("common.end")}
          </Button>
        </div>
      </Stack>
    </Dialog>
        <LoadingDialog open={working} />
        </>
  );
}

export default AddProductModal;
