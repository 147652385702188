
import  "./styles.css";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { toast } from 'react-hot-toast';
import { useForm } from "react-hook-form";
import config from '../../config.json';
import {useTranslation} from "react-i18next";
import axiosInstance from "../../utils/axsioInstance";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../utils/globalContext";
import { useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import LoadingDialog from "../../components/Common/loadingDialog";
import { Icon } from "@mui/material";
export default  function ChangePassword() {

  const { t } =  useTranslation('common');
  const { userID } = useParams();
  const navigate  =  useNavigate();
  const { user, setUser } = useGlobalContext();
  const [working, setWorking] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");

  const validatePassword = (value : string) => {
    const { new_password } = watch();
    return value === new_password || t('settings.password_mismatch');
  };


const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    getValues,
    watch,
    reset,
  } = useForm();


  const sendReset = async (data: any) => {

      try{
        const isValid = await trigger();
      if (!isValid) {
       return;
      }
     setWorking(true);
const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    formData.append('token', userID ?? "");
      const response = await axiosInstance
        .post(config.URLS.do_reset, formData);
  
      setError(!response.data.success);
      setMsg(t(response.data.message));
      setDone(true);

    }
    catch(error){
      
        toast.error(t("common.request_error"));
    }
    finally{
      setWorking(false);
    }
  };
      console.log(userID);
  return (

    <div className={"container"}>
      <div className={"content"}>
        <div className={"logo"}>
          <img
          src="/images/logo.png"
          height={64}
          alt="Picture of the author"
          className={"logoImg"}
          />
        </div>
        { !done && (
    <form onSubmit={handleSubmit(sendReset)} >

          <Stack spacing={4}>
          <h1 className={"h1"}>{t('login.change_password')}</h1>
          <h2 className={"h2"}>{t('login.change_password_info')}</h2>
           <TextField
                     {...register("new_password", { required: true })}
                      type="password"
                       helperText={
                      errors.new_password ? t("login.required_field") : ""
                    }
                      label={t('settings.new_password')}
                      variant="outlined"
                    />
                    <TextField
                     {...register("confirm_password", { required: true , validate: validatePassword })}
                      type="password"
                      
                       helperText={
                          errors.confirm_password ? t("settings.password_mismatch") : ""
                        }
                      label={t('settings.confirm_password')}
                      variant="outlined"
                    />
            <Button size="large" variant="contained" onClick={handleSubmit(sendReset)}>{t('common.edit')}</Button>

        </Stack>
              </form>)}
               { done && (


          <Stack spacing={4}>
            <h1 style={{textAlign:"center"}}>{error?<ErrorIcon sx={{ fontSize: 100, color: 'red' }} /> :<CheckCircleIcon sx={{ fontSize: 100, color: 'green' }} />}</h1>
          <h2 className={"h2"} style={{textAlign:"center"}}>{msg}</h2>
                {error?
                            <Button onClick={()=>{navigate('/resetPassword')}} href="#text-buttons">{t("login.forgot_password")}</Button>
                        :
                  <Button onClick={()=>{navigate('/login')}} href="#text-buttons">{t("login.login")}</Button>
                        }
          
        </Stack>
            )}

          </div>
    <LoadingDialog open={working} />
        </div>
    )
    
    
  }
  