
import { useState, useEffect, ChangeEvent } from "react";
import axiosInstance from "../../utils/axsioInstance";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import * as MI from '@mui/icons-material';
import * as I from '../../components/Icons';
import Moment from 'react-moment';
import config from '../../config.json';
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import LoadingDialog from "../Common/loadingDialog";
function OrderDetails({ orderData ,setRefresh,setOrderData}: { orderData: any,setRefresh: React.Dispatch<React.SetStateAction<boolean>>,setOrderData:React.Dispatch<React.SetStateAction<any>>}) {
  const [working, setWorking] = useState(false);
 
const { t } = useTranslation("common");
    const StepUp = async ()=>{
      try{
        setWorking(true);
      
        const response = await axiosInstance.post(config.URLS.update_order,{
        'order' : orderData.id
      });
      if(response.data.success){
        toast.success(t(response.data.message));
        setOrderData(response.data.order);
        setRefresh(true);
      }else{
          toast.error(t(response.data.message));
      }}
      catch(error){
          toast.error(t('common.request_error'));
      }
      finally{
        setWorking(false);
      }
    };
    const AcceptOrder = async()=>{
      try{
        setWorking(true);
      
        const response = await axiosInstance.post(config.URLS.accept_order,{
        'order' : orderData.id
      });
      if(response.data.success){
        toast.success(t(response.data.message));
        setOrderData(response.data.order);
        setRefresh(true);
      }else{
          toast.error(t(response.data.message));
      }}
      catch(error){
          toast.error(t('common.request_error'));
      }
      finally{
        setWorking(false);
      }
    };
    
     const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async() => {
    try{
      setWorking(true);
    const response = await axiosInstance.post(config.URLS.cancel_order,{
        'order' : orderData.id,
        'reason' : reason,
      });
      if(response.data.success){
        toast.success(t(response.data.message));
        setOrderData(response.data.order);
        setRefresh(true);
      }else{
          toast.error(t(response.data.message));
      }
    setOpen(false);}
     catch(error){
          toast.error(t('common.request_error'));
      }
      finally{
        setWorking(false);
      }
  };

  const handleChange = (event :  ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };
  
  return (
            <div className="bloc phone-preview">
              <div className="notif">
                <div className="state">
                    {orderData?.state.state_name}
                </div>
                <div className="close-details-container" onClick={()=>{setOrderData(null)}}><I.Close  /></div>
              </div>
              <h2>Détails de la commande</h2>
              <div className="order-details">
                <div className="flex">
                  <div className="id">N° {orderData?.tracking_id}</div>
                  <div className="opticien">{orderData?.magasin}</div>
                </div>
                <div className="date"><Moment format={'DD/MM/YYYY HH:mm'}>{orderData?.created_at}</Moment></div>
                
              </div>
              <div className="items">
          
                <div className="item">
                  <div className="label"> Corrections OG:  { orderData?.axe_G && <> Axe <b>{orderData?.axe_G}</b>/</> } CYL <b>{orderData?.cylindre_G}</b>/SPH <b>{orderData?.sphere_G}</b></div>
                </div>
                
                <div className="item">
                  <div className="label"> Corrections OD: { orderData?.axe_D && <> Axe <b>{orderData?.axe_G}</b>/</> }  CYL <b>{orderData?.cylindre_D}</b>/SPH <b>{orderData?.sphere_D}</b></div>
                </div>
                
                <div className="item">
                  <div className="price"><b>{orderData?.designation}</b></div>
                </div>
                
              </div>
              <h2>Détails de la commande</h2>
              <div className="items">
                <div className="item">
                  <div className="label">Client</div>
                  <div className="price">{orderData?.magasin}</div>
                </div>
                <div className="item">
                  <div className="label">Rèfèrence</div>
                  <div className="price">{orderData?.ref_sage}</div>
                </div>
                <div className="item">
                  <div className="label">Téléphone</div>
                  <div className="price">{orderData?.tel}</div>
                </div>
                <div className="item">
                  <div className="label">Ville</div>
                  <div className="price">{orderData?.city.city_name}</div>
                </div>
                <div className="item">
                  <div className="label">Adresse</div>
                  <div className="price">{orderData?.address}</div>
                </div>
              </div>

              <div className="items">
                <div className="item">
                  <div className="label"><b>OG : </b><br />{orderData?.designation_G}</div>
                </div>
                <div className="item">
                  <div className="label"><b>REF : </b> {orderData?.ref_product_G}</div>
                </div>
                <div className="item">
                  <div className="label">&nbsp;</div>
                  <div className="price">{orderData?.prix_vente_G}</div>
                </div>

                  <div className="item">
                  <div className="label"><b>OD : </b><br />{orderData?.designation_D}</div>
                </div>
                <div className="item">
                  <div className="label"><b>REF : </b> {orderData?.ref_product_D}</div>
                </div>
                <div className="item">
                  <div className="label">&nbsp;</div>
                  <div className="price">{orderData?.prix_vente_D}</div>
                </div>
       
              </div>

                <div className="totals">
                  <div className="flex">Total</div>
                  <div className="total">{orderData?.total} DH</div>
                </div>
                <div className="column btn-actions gap-24">
                  {
                  ( orderData.order_state != config.STATES.FINISHED &&orderData.order_state != config.STATES.CANCELED  )
                  &&
                  (  <>
                  {
                    orderData.accepted_at == null ?
                  <Button variant="contained" onClick={AcceptOrder} startIcon={<MI.Check />}>
                      {t('orders.accept_order')}
                  </Button> : 
                  <Button variant="contained" onClick={StepUp} startIcon={<MI.Check />}>
                    {t('orders.update_order')} 
                  </Button>}
                  <Button
                    variant="outlined"
                    onClick={()=>setOpen(true)}
                    startIcon={<MI.Close color="error" />}
                  >
                    {t('orders.cancel_order')}
                  </Button></>)}
                </div>
                 <Dialog open={open} onClose={handleClose}>
        <DialogTitle>  {t('common.confirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           {t('orders.are_u_sure_cancel')}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            label="Reason"
            fullWidth
            value={reason}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('common.cancel')}
          </Button>
          <Button onClick={handleConfirm} color="secondary">
            {t('common.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
                  <LoadingDialog open={working} />

              </div>);
}

export default OrderDetails;
