
import  "./styles.css";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { toast } from 'react-hot-toast';
import { useForm } from "react-hook-form";
import config from '../../config.json';
import {useTranslation} from "react-i18next";
import axiosInstance from "../../utils/axsioInstance";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../utils/globalContext";
import { useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import LoadingDialog from "../../components/Common/loadingDialog";
import { Icon } from "@mui/material";
export default  function ResetPassword() {

  const { t } =  useTranslation('common');

  const navigate  =  useNavigate();
  const { user, setUser } = useGlobalContext();
  const [working, setWorking] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");


const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    getValues,
    reset,
  } = useForm();


  const sendEmail = async (data: any) => {
      try{
        const isValid = await trigger();
        if (!isValid) {
          return;
        }
        setWorking(true);

        const response = await axiosInstance.post(config.URLS.do_forgot, data);
        setError(!response.data.success);
        setMsg(t(response.data.message));
        setDone(true);

    }
    catch(error){
        toast.error(t("common.request_error"));
    }
    finally{
      setWorking(false);
    }
  };
  return (

    <div className={"container"}>
      <div className={"content"}>
        <div className={"logo"}>
          <img
          src="/images/logo.png"
          height={64}
          alt="Picture of the author"
          className={"logoImg"}
          />
        </div>
        { !done && (
    <form onSubmit={handleSubmit(sendEmail)} >

          <Stack spacing={4}>
          <h1 className={"h1"}>{t('login.reset_password')}</h1>
          <h2 className={"h2"}>{t('login.reset_password_info')}</h2>
          <TextField
              id="outlined-required"
              
              {...register("email", {
                      required: true,
                            pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: t('common.email_invalid'),
                          },
                    })}
                      helperText={
                      errors.email ? t("common.email_invalid") : ""
                    }
             placeholder={t('settings.email')}
             label={t('settings.email')}

           
              margin="normal"
            />
            <Button size="large" variant="contained" onClick={handleSubmit(sendEmail)}>{t('common.send')}</Button>

        </Stack>
              </form>)}
               { done && (


          <Stack spacing={4}>
            <h1 style={{textAlign:"center"}}>{error?<ErrorIcon sx={{ fontSize: 100, color: 'red' }} /> :<CheckCircleIcon sx={{ fontSize: 100, color: 'green' }} />}</h1>
          <h2 className={"h2"} style={{textAlign:"center"}}>{msg}</h2>

          
        </Stack>
            )}

          </div>
    <LoadingDialog open={working} />
        </div>
    )
    
    
  }
  