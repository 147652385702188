import React from 'react';
import './index.css';


const SmallScreenMessage = () => {
  return (
     <div className="overlay">
        <div className="message-container">
          <div className="xicon">
            &#9888;
          </div>
          <div className="text">
            <h2>Veuillez utiliser un écran plus grand</h2>
            <p>Pour une expérience optimale, veuillez utiliser un appareil doté d'un écran plus grand pour afficher le tableau de bord.</p>
          </div>
        </div>
      </div>
  );
};

export default SmallScreenMessage;