import { useTranslation } from "react-i18next";
import * as I from "../Icons";

import "./styles.css";
import { useGlobalContext } from "../../utils/globalContext";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { useState } from "react";

export default function Sidebar({isItemSelected = false}) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
   const [open, setOpen] = useState(false);

  const handleLogout = () => {

    localStorage.removeItem('token');
    
 
    localStorage.clear();
  
    window.location.href = '/login';
  };
 const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { user, setUser } = useGlobalContext();
  return (
    <div className={`sidebar ${isItemSelected ? 'collapsed' : ''}`}>
      <div className="logo">
      </div>
      <div className="menu"  style={{paddingTop:52}}>
        <div
          className={`item ${window.location.pathname.split("/")[1] == "dashboard" ? "active" : ""}`}
          onClick={() => navigate("/dashboard")}
        >
          <div className="icon">
            <I.Dashboard />
          </div>
          <div className="label">{t("dashboard.dashboard")}</div>
        </div>
        <div
          className={`item ${window.location.pathname.split("/")[1] == "products" ? "active" : ""}`}
          onClick={() => navigate("/products")}
        >
          <div className="icon">
            <I.Product />
          </div>
          <div className="label">{t("dashboard.products")}</div>
        </div>
        <div
          className={`item ${window.location.pathname.split("/")[1] == "orders" ? "active" : ""}`}
          onClick={() => navigate("/orders")}
        >
          <div className="icon">
            <I.Order />
          </div>
          <div className="label">{t("dashboard.orders")}</div>
        </div>  
        <div
          className={`item ${window.location.pathname.split("/")[1] == "clients" ? "active" : ""}`}
          onClick={() => navigate("/clients")}
        >
          <div className="icon">
            <I.Clients />
          </div>
          <div className="label">{t("dashboard.clients")}</div>
        </div>
        <div
          className={`item ${window.location.pathname.split("/")[1] == "banners" ? "active" : ""}`}
          onClick={() => navigate("/banners")}
        >
          <div className="icon">
            <I.Media />
          </div>
          <div className="label">{t("dashboard.banners")}</div>
        </div>
        <div
          className={`item ${window.location.pathname.split("/")[1] == "settings" ? "active" : ""}`}
          onClick={() => navigate("/settings")}
        >
          <div className="icon">
            <I.Settings />
          </div>
          <div className="label">{t("dashboard.settings")}</div>
        </div>
      </div>
      <div className="user">
        <div className="profile">
          <div className="initial">JD</div>
          <div className="label">{user?.lname+" "+user?.fname ?? ""}</div>
        </div>
        <div onClick={handleOpen} className="logout">

          <I.LogOut  />

        </div>
      </div>
       <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('common.confirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('common.are_u_sure_disconnect')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('common.no')}
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            {t('common.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
