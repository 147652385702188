
import { useState, useEffect, ChangeEvent } from "react";
import axiosInstance from "../../utils/axsioInstance";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import * as MI from '@mui/icons-material';
import * as I from '../Icons';
import Moment from 'react-moment';
import config from '../../config.json';
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import LoadingDialog from "../Common/loadingDialog";
import EditClient from "./editClient";
import { title } from "process";
function ClientDetails({ clientData ,setRefresh,setClientData}: { 
  clientData: any,
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
  ,setClientData:React.Dispatch<React.SetStateAction<any>>}) {

  const { t } = useTranslation("common");
   
    const openMaps = () => {
    const { latitude, longitude } = clientData;
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, '_blank');
  };

  const handleChange = (event :  ChangeEvent<HTMLInputElement>) => {
    setReference(event.target.value);
  };    const handleChangeT = (event :  ChangeEvent<HTMLInputElement>) => {
    setNTitle(event.target.value);
  };    const handleChangeB = (event :  ChangeEvent<HTMLInputElement>) => {
    setNBody(event.target.value);
  };  

  const handleSend = async() => {
    try{
      if(notif_title==''){
       toast.error(t('clients.type_title'));
        return;
      }
      if(notif_body==''){
       toast.error(t('clients.type_title'));
        return;
      }
      setWorking(true);
    const response = await axiosInstance.post(config.URLS.client_send_notif,{
        'uuid' : clientData?.uuid,
        'title' : notif_title,
        'body' : notif_body,
      });
      if(response.data.success){
        toast.success(t(response.data.message));
        setNBody('');
        setNTitle('');
      }else{
          toast.error(t(response.data.message));
      }
   }
     catch(error){
          toast.error(t('common.request_error'));
      }
      finally{
        setWorking(false);
         setOpenNotify(false);
      }
  };
  const handleVerify = async() => {
    try{
      if(reference==''){
       toast.error(t('clients.type_reference'));
        return;
      }
      setWorking(true);
    const response = await axiosInstance.post(config.URLS.client_verify,{
        'uuid' : clientData?.uuid,
        'reference' : reference,
      });
      if(response.data.success){
        toast.success(t(response.data.message));
        setReference('');
        setClientData(response.data.client);
        setRefresh(true);
      }else{
          toast.error(t(response.data.message));
      }
   }
     catch(error){
          toast.error(t('common.request_error'));
      }
      finally{
        setWorking(false);
         setOpenVerify(false);
      }
  };
  const blockUser = async()=>{
    try{
      setWorking(true);

      const response = await axiosInstance
        .post(config.URLS.client_block, {'uuid':clientData?.uuid})

      if(response.data.success)
      {
          toast.success(t(response.data.message));
          setRefresh(true);
          setClientData(response.data.client);

      }else{
          toast.error(t(response.data.message));
      }
    }
    catch(error){
      
        toast.error(t("common.request_error"));
    }
    finally{
      setWorking(false);
    } 
  };
  const handleDelete = async () => {
    try{
    setWorking(true);
    const response = await axiosInstance
      .post(config.URLS.client_delete, {'uuid':clientData?.uuid})

    if(response.data.success)
    {
        toast.success(t(response.data.message));
        setOpenDelete(false);
        setRefresh(true);
    }else{
        toast.error(t(response.data.message));
    }
  }
  catch(error){
    
      toast.error(t("common.request_error"));
  }
  finally{
    setWorking(false);
  } 
  };
  const [errorPassword, seterrorPassword] = useState("");
  const [working, setWorking] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
   const [openVerify, setOpenVerify] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [reference, setReference] = useState('');
  const [openNotify, setOpenNotify] = useState(false);
  const [notif_title, setNTitle] = useState('');
  const [notif_body, setNBody] = useState('');

  return (
            <div className="bloc phone-preview">
              <div className="notif">
                <div className="state">
                    {clientData?.magasin}
                </div>
                <div className="close-details-container" onClick={()=>{setClientData(null)}}><I.Close  /></div>
              </div>
              <div className="order-details">
                <div className="flex">
                  <div className="id">Magasin : {clientData?.magasin}</div>
                  <div className="opticien">{clientData?.full_name}</div>
                  <div className="opticien">Ref : {clientData?.reference_sage ?? 'N/A'}</div>
                </div>
                {
                  (clientData?.latitude && clientData?.longitude) &&  
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={openMaps}
                        >     
                         <MI.PinDrop />
                  </Button>
                }
               
                
              </div>

              <h2>Coordonnées du client</h2>
              <div className="items">
                <div className="item">
                  <div className="label">E-mail</div>
                  <div className="price">{clientData?.email}</div>
                </div>
                <div className="item">
                  <div className="label">Téléphone</div>
                  <div className="price">{clientData?.tel}</div>
                </div>
                <div className="item">
                  <div className="label">Ville</div>
                  <div className="price">{clientData?.city_name}</div>
                </div>
                <div className="item">
                  <div className="label">Adresse</div>
                  <small className="price">{clientData?.addresse}</small>
                </div>
              </div>

        
              <div className="row btn-actions gap-8 d-flex" >

              
              { clientData?.validated_at==null &&
              <Button
              style={{flex:1}}
                variant="outlined"
                onClick={()=>{setOpenVerify(true)}}
                startIcon={<MI.CheckCircle />}
              >
                {t('clients.verify_client')}
              </Button>
              }  { clientData?.validated_at!=null &&
              <Button
              style={{flex:1}}
                variant="outlined"
                onClick={()=>{setOpenNotify(true)}}
                startIcon={<MI.Send />}
              >
                {t('clients.notify_client')}
              </Button>
              }
              
                <Button 
                style={{flex:1}}
                variant="outlined" 
                color={(clientData?.suspended_at ? "success" : "error")}
                onClick={blockUser} 
                startIcon={(clientData?.suspended_at ?<MI.CheckCircle /> :<MI.Block />)}
                >
                {( clientData?.suspended_at ? t('common.unblock') : t('common.block'))}
              </Button>
             
            </div>
            <div className="row btn-actions gap-8">

             
                <Button                 
                style={{flex:1}}
                variant="outlined" 
                color="warning"  
                onClick={()=>{setOpenEdit(true)}} 
                startIcon={<MI.Edit />}>
                {t('common.edit')}
              </Button>
              <Button                 
              style={{flex:1}}
              variant="outlined" 
              color="error" 
              onClick={()=>{setOpenDelete(true)}} 
              startIcon={<MI.Delete color={"error"} />}>
                {t('common.delete')}
              </Button>
            </div>
            <LoadingDialog open={working} />
            <EditClient setClientData={setClientData} Data={clientData} open={openEdit} setRefresh={setRefresh} setOpen={setOpenEdit} />
            <Dialog open={openVerify} onClose={()=>{setOpenVerify(false)}}>
                  <DialogTitle>{t('clients.verify_client')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                    {t('clients.are_u_sure_verify')}
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="reference"
                      label={t('clients.reference')}
                      fullWidth
                      value={reference}
                      onChange={handleChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={()=>{setOpenVerify(false)}} color="primary">
                      {t('common.cancel')}
                    </Button>
                    <Button onClick={handleVerify} color="secondary">
                      {t('common.confirm')}
                    </Button>
                  </DialogActions>
            </Dialog>
            <Dialog open={openDelete}onClose={()=>{setOpenDelete(false)}}>
                  <DialogTitle>{t('clients.delete_client')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                    {t('clients.are_u_sure_delete')}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={()=>{setOpenDelete(false)}} color="primary">
                      {t('common.cancel')}
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                      {t('common.delete')}
                    </Button>
                  </DialogActions>
            </Dialog>
            <Dialog open={openNotify} onClose={()=>{setOpenNotify(false)}}>
                  <DialogTitle>{t('clients.do_notify_client')}</DialogTitle>
                  <DialogContent>
      
                    <TextField
                      autoFocus
                      margin="dense"
                      id="reference"
                      label={t('clients.title_notif')}
                      fullWidth
                      value={notif_title}
                      onChange={handleChangeT}
                    />
                      <TextField
                      autoFocus
                      margin="dense"
                      id="title_notif"
                      multiline={true}
                      
                      label={t('clients.body_notif')}
                      fullWidth
                      value={notif_body}
                      onChange={handleChangeB}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={()=>{setOpenNotify(false)}} color="primary">
                      {t('common.cancel')}
                    </Button>
                    <Button onClick={handleSend} color="secondary">
                      {t('common.send')}
                    </Button>
                  </DialogActions>
            </Dialog>
              </div>
            );
}

export default ClientDetails;
