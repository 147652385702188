import { useEffect, useState } from "react";
import "./styles.css";
import Sidebar from "../../components/Sidebar";
import {
  Button,
  FormControl,
  Input,
  InputAdornment,
} from "@mui/material";
import * as MI from "@mui/icons-material";
import * as I from "../../components/Icons";
import {
  DataGrid,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid";
import { toast } from "react-hot-toast";
import axiosInstance from "../../utils/axsioInstance";
import AddProductModal from "../../components/Products/addProduct";
import { useTranslation } from "react-i18next";
import ProductPreview from "../../components/Products/productPreview";
import NoData from "../../animations/no-data.json";
import Lottie from 'lottie-react';
import config from '../../config.json';
import LoadingDialog from "../../components/Common/loadingDialog";
import debounce from "lodash/debounce";
import AuthenticatedImage from "../../components/Common/authImg";
import { frFR } from '@mui/x-data-grid/locales';




export default function Products() {
  const { t } = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [addOpen, setaddOpen] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [search, setSearch] = useState("");
  const [working, setWorking] = useState(false);
  const [selectedProduct, setselectedProduct] = useState(null);

  
const columns: GridColDef[] = [
  {
    field: "pic",
    headerName: "Image",
    flex: 1,
    cellClassName: "centered-content",
    renderCell: (params: GridCellParams) => (
      <div className="row center gap-16" style={{ position:"relative"}}>
        <AuthenticatedImage  style={{width:"100%"}}  url={config.URLS.product_image+ params.row.pic} />
      </div>
    ),
  },
  {
    field: "product_name",
    headerName: "Article",
    flex: 1,
    cellClassName: "centered-content",
    renderCell: (params: GridCellParams) => (

          <div className="">{params.row.product_name}</div>
    ),
  },
  {
    field: "description",
    headerName: "Description",
    flex: 4,
    renderCell: (params: GridCellParams) => (
          <div className="product-description">{params.row.description}</div>
    ),
    cellClassName: "centered-content",
  },

];

  const debouncedSearch = debounce((searchValue) => {
    setSearch(searchValue); 
    setWorking(true);setRefresh(true);
  }, 1000);




    const fetchData = async ( ) => {
     
      try {
         const formData = new FormData();

          formData.append('search_term', search ?? "");

        const response = await axiosInstance.post(config.URLS.list_products, formData);
        setRows(response.data);
        setDataLoaded(true);
        setRefresh(false);
        setselectedProduct(null);
        setWorking(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  useEffect(() => {
     if(!refresh)
          return;
        fetchData();
  }, [refresh]);

  const openDialog = () => {
    setaddOpen(true);
  };




  return (
    <div className="page products">
      <div className="row">
        <Sidebar isItemSelected={selectedProduct != null}  />
        <div className="content flex">
          <div className="row main-header">
            <div className="page-title row gap-16 center">
              <I.Product />
              <h1>Catalogue</h1>
            </div>
            <FormControl size="small" sx={{ display: "flex", alignItems: "flex-end" }}>
              <Input
                className="search-input"
                disableUnderline
                startAdornment={
                    <InputAdornment position="start">
                      <I.Search />
                    </InputAdornment>
                  }
                placeholder={t("products.search")}
                onChange={(event)=>{ debouncedSearch(event.target.value);}}
              />
            </FormControl>

            <Button
            disableElevation
              variant="contained"
              startIcon={<MI.Add />}
              onClick={openDialog}
            >
              {t("products.add_product")}
            </Button>
          </div>
          <div className="divider-56"></div>

          <div className="column gap-24">
            <div className="row gap-24">
              <div className="inner-container">
                
                <div className="data-grid-container bloc flex">
                  <div className="filter-bar">
                         
                  </div>
                    {(rows.length<=0)?
                  ( <div className="bloc no-data">
                      <Lottie animationData={NoData}  style={{ height: 160 }} />
                      <h1>{ dataLoaded?t('products.no_data'):t('common.loading')}</h1>
                    </div>):
               ( 
                  <DataGrid
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    getRowHeight={() => "auto"}
                    autosizeOnMount
                    rows={rows}
                    columns={columns}
                    onRowClick={(
                      params: GridRowParams,
                      event: MuiEvent,
                      details: GridCallbackDetails,
                    ) => {
                      setselectedProduct(params.row);
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: {pageSize:10},
                      },
                    }}
                  />
                )}
                </div>
              </div>

              {selectedProduct && (
                <ProductPreview productData={selectedProduct} setRefresh={setRefresh} setProduct={setselectedProduct} />
              )}
            </div>
          </div>
        </div>
      </div>
      <AddProductModal  open={addOpen} setRefresh={setRefresh} setOpen={setaddOpen} />
    <LoadingDialog open={working} />
    </div>
  );
}
